import React, { useEffect, useState } from 'react';
import { Box, Fade, Grow, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AdvancedToolFilterForm } from './AdvancedToolFilterForm';
import { isEmpty } from '../../../utils/ObjectUtils';
import { ScrollableContainer } from '../../Common/ScrollableContainer';

const NAV_WIDTH = 280;

const useStyles = makeStyles(() => ({
  background: {
    backgroundColor: '#00000040',
    position: 'relative',
    zIndex: 20,
    width: '100%',
    height: '100%',
  },
  outside: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '100%',
    height: '100%',
  },
  paper: {
    position: 'absolute',
    right: 0,
    width: NAV_WIDTH,
    height: '100%',
    padding: 0,
    borderRadius: 0,
    backgroundColor: '#FFFFFFF3',
  },
}));

export const AdvancedToolFilterNav = ({ open, onClose, options, filters, updateFilters }) => {
  const styles = useStyles();
  const [state, setState] = useState({ fade: false, grow: false });
  useEffect(() => {
    if (open === true) {
      setState({ fade: true, grow: false });
      setTimeout(() => setState({ fade: true, grow: true }), 350);
      return;
    }
    setState({ fade: true, grow: false });
    setTimeout(() => setState({ fade: false, grow: false }), 650);
  }, [open]);

  return (
    <nav>
      <Fade in={state.fade}>
        <Box className={styles.background}>
          <Box className={styles.outside} onClick={onClose} />
          <Grow in={state.grow} style={{ transformOrigin: '100% 0 0' }}>
            <Paper elevation={8} className={styles.paper}>
              <ScrollableContainer>
                {!!options && options.length && filters && !isEmpty(filters) && (
                  <AdvancedToolFilterForm
                    options={options}
                    filters={filters}
                    updateFilters={updateFilters}
                  />
                )}
              </ScrollableContainer>
            </Paper>
          </Grow>
        </Box>
      </Fade>
    </nav>
  );
};
