import React from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(3),
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: theme.palette.primary.light,
    },
    '& .MuiCheckbox-root': {
      padding: '5px 8px 3px 0',
    },
    '& span.MuiTypography-body1': {
      fontSize: '.9375em',
      lineHeight: 1.12,
    },
    '& legend.MuiFormLabel-root': {
      fontWeight: 700,
      padding: '24px 0 8px',
      '&.Mui-focused': {
        color: 'rgba(0, 0, 0, 0.54)',
      },
    },
  },
}));

const AdvancedToolFilterGroup = ({ option, filters, updateFilters }) => {
  const { t } = useTranslation();

  const handleChange = (event) => {
    updateFilters({ ...filters, [event.target.name]: event.target.checked });
  };

  return (
    <>
      <FormLabel component="legend">{t(option.label)}</FormLabel>
      <FormGroup>
        {!!option && option.filters.map((field, index) => (
          <FormControlLabel
            key={field.toString()}
            control={<Checkbox size="small" checked={filters[field]} onChange={handleChange} name={field} />}
            label={option.render(option.elements[index])}
          />
        ))}
      </FormGroup>
    </>
  );
};

export const AdvancedToolFilterForm = ({ options, filters, updateFilters }) => {
  const styles = useStyles();

  return (
    <Box className={styles.root}>
      <FormControl component="fieldset" className={styles.formControl}>
        {options.map((option, key) => (
          <AdvancedToolFilterGroup key={key.toString()} option={option} filters={filters} updateFilters={updateFilters} />))}
      </FormControl>
    </Box>
  );
};
