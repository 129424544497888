import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import { GET_STUDY } from '../../../graphql/queries';
import Layout from '../../../components/MenuLayout';
import { StudyAdvancedToolsView } from '../../../components/AdvancedTools/StudyAdvancedToolsView';
import { Error } from '../../../components/Common/styled/Form';
import { isEmpty } from '../../../utils/ObjectUtils';
import Loading from '../../../components/Common/Loading';

export default ({ studyId }) => {
  const { loading, data, error } = useQuery(
    GET_STUDY,
    { variables: { uuid: studyId } },
  );

  return (
    <Layout>
      {error && error.graphQLErrors && error.graphQLErrors.length && (
        <Error>{error.graphQLErrors[0].message}</Error>
      )}
      {
        loading || isEmpty(data)
          ? <Loading />
          : <StudyAdvancedToolsView study={data.dicomStudy} />
      }
    </Layout>
  );
};

// {/* <StudyAdvancedToolsViewUI studyId={studyId} study={data.dicomStudy} /> */}
