// eslint-disable-next-line max-classes-per-file
import { objectPathValue } from '../../../utils/ObjectUtils';

export class FilterCategory {
  constructor(id, prefix, label, path, render, parse) {
    this.id = id;
    this.prefix = prefix;
    this.label = label;
    this.path = path || this.id;
    this.render = render || ((i) => i);
    this.parse = parse || ((i) => i);
    this.find = (elem) => objectPathValue(elem, this.path);
    this.match = (elem) => this.parse(this.find(elem));
  }
}

export class DefinedFilterCategory extends FilterCategory {
  setFilters(options) {
    this.options = options;
    this.filters = options.map((option) => `${this.prefix}_${option}`);
    this.match = (elem) => elem;
    return this;
  }

  setLabels(labels) {
    this.elements = labels;
  }
}

export class Category {
  constructor(id, prefix, label, render) {
    this.id = id;
    this.prefix = prefix;
    this.label = label;
    this.render = render || ((i) => i);
  }
}
